import 'regenerator-runtime/runtime'
import interact from 'interactjs'
import retro from '@smarterlabs/retro-js'
import get from 'lodash/get'
// import AppendCss from 'append-css'

// const maxFlicker = .3
// const scanLineOpacity = .7
// const flickerSpeed = `0.3s`

// const crtCss = `
// @keyframes flicker {
// 	0% {
// 		opacity: ${maxFlicker * 0.27861};
// 	}
// 	5% {
// 		opacity: ${maxFlicker * 0.34769};
// 	}
// 	10% {
// 		opacity: ${maxFlicker * 0.23604};
// 	}
// 	15% {
// 		opacity: ${maxFlicker * 0.90626};
// 	}
// 	20% {
// 		opacity: ${maxFlicker * 0.18128};
// 	}
// 	25% {
// 		opacity: ${maxFlicker * 0.83891};
// 	}
// 	30% {
// 		opacity: ${maxFlicker * 0.65583};
// 	}
// 	35% {
// 		opacity: ${maxFlicker * 0.67807};
// 	}
// 	40% {
// 		opacity: ${maxFlicker * 0.26559};
// 	}
// 	45% {
// 		opacity: ${maxFlicker * 0.84693};
// 	}
// 	50% {
// 		opacity: ${maxFlicker * 0.96019};
// 	}
// 	55% {
// 		opacity: ${maxFlicker * 0.08594};
// 	}
// 	60% {
// 		opacity: ${maxFlicker * 0.20313};
// 	}
// 	65% {
// 		opacity: ${maxFlicker * 0.71988};
// 	}
// 	70% {
// 		opacity: ${maxFlicker * 0.53455};
// 	}
// 	75% {
// 		opacity: ${maxFlicker * 0.37288};
// 	}
// 	80% {
// 		opacity: ${maxFlicker * 0.71428};
// 	}
// 	85% {
// 		opacity: ${maxFlicker * 0.70419};
// 	}
// 	90% {
// 		opacity: ${maxFlicker * 0.7003};
// 	}
// 	95% {
// 		opacity: ${maxFlicker * 0.36108};
// 	}
// 	100% {
// 		opacity: ${maxFlicker * 0.24387};
// 	}
// }
// .crt::after {
// 	content: " ";
// 	display: block;
// 	position: fixed;
// 	top: 0;
// 	left: 0;
// 	bottom: 0;
// 	right: 0;
// 	background: rgba(18, 16, 16, 0.1);
// 	opacity: 0;
// 	z-index: 9999;
// 	pointer-events: none;
// 	animation: flicker ${flickerSpeed} infinite;
// }
// .crt::before {
// 	content: " ";
// 	display: block;
// 	position: fixed;
// 	top: 0;
// 	left: 0;
// 	bottom: 0;
// 	right: 0;
// 	background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%), linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
// 	z-index: 9999;
// 	background-size: 100% 2px, 3px 100%;
// 	pointer-events: none;
// 	opacity: ${scanLineOpacity};
// }`
// console.log(`crtCss`, crtCss)
// new AppendCss(crtCss)

const embeds = {
	zork: `/zork/?story=/zork/lib/zdungeon.z5`,
	paint: `/paint`,
	minesweeper: `/minesweeper/`,
}
const html = {
	video: `<iframe width="100%" height="100%" src="https://www.youtube.com/embed/Rjf9MwIwnb0?controls=0&autoplay=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
}
const preferSizes = {
	minesweeper: [546, 400],
}

function applyStyles(el, styles){
	for(let i in styles){
		el.style[i] = styles[i]
	}
}

let curZ = 9
const startX = 10
const startY = 30
const maxX = startX + 30
const maxY = startY + 30
const xInterval = 10
const yInterval = 10
let curX = startX
let curY = startY
function showWindow(sel){
	const el = getWindow(sel)
	if(!el) return

	// Set other windows to inactive
	const windows = document.querySelectorAll(`[data-window]`)
	for(let i = windows.length; i--;){
		const win = windows[i]
		if(el == win) continue
		if(win.querySelector(`.overlay`)) continue
		const content = win.querySelector(`.content`)
		if(!content) continue
		const overlay = document.createElement(`div`)
		overlay.className = `overlay`
		applyStyles(overlay, {
			position: `absolute`,
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
			background: `rgba(0, 0, 0, .3)`,
		})
		content.appendChild(overlay)
	}

	// Remove overlay on this window if there is any
	const overlay = el.querySelector(`.overlay`)
	console.log(`Checking for overlay...`)
	if(overlay){
		console.log(`removing overlay`)
		overlay.remove()
	}
	else{
		console.log(`no overlay in`, el)
	}

	// Make window active if already open
	const computed = window.getComputedStyle(el)
	const display = computed.getPropertyValue(`display`)
	if(display === `block`){
		el.style.zIndex = curZ++
		return
	}

	// Calculate initial size
	const bodyW = document.body.clientWidth
	const bodyH = document.body.clientHeight
	// Desktop
	if(bodyW >= 450){
		const wPadding = bodyW * .1
		const hPadding = bodyH * .1
		const totalMaxW = 2000
		const totalMaxH = 2000
		let maxW = bodyW - wPadding
		let maxH = bodyH - hPadding - 100
		if(maxW > totalMaxW) maxW = totalMaxW
		if(maxH > totalMaxH) maxH = totalMaxH
		let setHeight = (maxW * 9) / 16
		let setWidth
		if(setHeight > maxH){
			setWidth = (16 * maxH) / 9
		}
		else{
			setWidth = maxW
		}
		console.log(`sel`, sel)
		console.log(`preferSizes`, preferSizes)
		if(preferSizes[sel]){
			const s = preferSizes[sel]
			if(setWidth > s[0]) setWidth = s[0]
			if(setHeight > s[1]) setHeight = s[1]
			console.log(`window.innerHeight`, window.innerHeight)
			if((window.innerWidth - 20) > s[0]) setWidth = s[0]
			if((window.innerHeight - 100) > s[1]) setHeight = s[1]
		}
		console.log(`sizes`, setWidth, setHeight)
		if(setHeight > maxH){
			setHeight = maxH
		}
		el.style.width = `${setWidth}px`
		el.style.height = `${setHeight}px`
		el.style.top = `${curX}px`
		el.style.left = `${curY}px`
		curX += xInterval
		curY += yInterval
		if(curX > maxX) curX = startX
		if(curY > maxY) curY = startY
	}
	// Mobile
	else{
		const padding = 10
		el.style.top = `${padding}px`
		el.style.left = `${padding}px`
		el.style.width = `${bodyW - (padding * 2)}px`
		el.style.height = `${bodyH - 100}px`
	}

	// Show window
	el.style.display = `block`
	el.style.zIndex = curZ++

	// Show contents if embedded
	if(embeds[sel]){
		const contentEl = el.querySelector(`.content`)
		contentEl.style.overflow = `hidden`
		contentEl.innerHTML = makeIframe(embeds[sel])
	}

	// Show contents if in dataset
	if(html[sel]){
		const contentEl = el.querySelector(`.content`)
		contentEl.innerHTML = html[sel]
	}
}
function hideWindow(sel){
	const el = getWindow(sel)
	if(!el) return
	el.style.display = `none`
	if(embeds[sel] || html[sel]){
		el.querySelector(`.content`).innerHTML = ``
	}
}
function getWindow(label){
	if(typeof label !== `string`) return label
	return document.querySelector(`[data-window="${label}"]`)
}

retro.createComponents({
	'[data-open]': {
		onClick: ({ event, node }) => {
			event.preventDefault()
			const winLabel = get(node, `dataset.open`)
			showWindow(winLabel)
		},
	},
	'[data-window]': {
		onClick: ({ node }) => {
			const winLabel = get(node, `dataset.window`)
			showWindow(winLabel)
		},
		onMount: ({ node }) => {
			node.style.touchAction = `none`
		},
	},
	'[data-close]': {
		onClick: ({ node, event }) => {
			event.stopPropagation()
			let depth = 0
			let el = node.parentElement
			let found = false
			while(depth < 5){
				if(el.dataset.window){
					found = true
					break
				}
				el = el.parentElement
				depth++
			}
			if(found){
				hideWindow(el)
			}
		},
	},
})
retro.mount()

function makeIframe(src){
	return `<iframe src='${src}' width='100%' height='100%' style='user-select:none'></iframe>`
}

// Make windows resizable
const windows = document.querySelectorAll(`.window`)
for(let i = windows.length; i--;){
	interact(windows[i])
		.resizable({
			edges: { left: true, right: true, top: false, bottom: true },
			// edges: {
			// 	left,
			// 	right,
			// 	bottom,
			// 	top: true,
			// },
			// cursorChecker: (action, interactable, element, interacting) => {
			// 	console.log(`action`, action.edges)
			// 	return 'sw-resize'
			// 	if (action.edges.bottom && action.edges.right) {
			// 		return 'sw-resize'
			// 	}
			// 	// etc.
			// },
	
			listeners: {
				move (event) {
					var target = event.target
					var x = (parseFloat(target.getAttribute(`data-x`)) || 0)
					var y = (parseFloat(target.getAttribute(`data-y`)) || 0)
		
					// update the element's style
					target.style.width = event.rect.width + `px`
					target.style.height = event.rect.height + `px`
		
					// translate when resizing from top or left edges
					x += event.deltaRect.left
					y += event.deltaRect.top
		
					target.style.webkitTransform = target.style.transform = `translate(${x}px, ${y}px)`
		
					target.setAttribute(`data-x`, x)
					target.setAttribute(`data-y`, y)
				},
			},
			modifiers: [
			// keep the edges inside the parent
				interact.modifiers.restrictEdges({
					outer: `parent`,
				}),
	
				// minimum size
				interact.modifiers.restrictSize({
					min: { width: 100, height: 50 },
				}),
			],
	
			inertia: true,
		})
		.draggable({
			listeners: { move: dragMoveListener },
			inertia: true,
			modifiers: [
				interact.modifiers.restrictRect({
					restriction: `parent`,
					endOnly: true,
				}),
			],
		})
}


function dragMoveListener (event) {
	var target = event.target
	const posInEl = event.client.y - event.rect.top
	if(posInEl > 35) return
	// keep the dragged position in the data-x/data-y attributes
	var x = (parseFloat(target.getAttribute(`data-x`)) || 0) + event.dx
	var y = (parseFloat(target.getAttribute(`data-y`)) || 0) + event.dy
 
	// translate the element
	target.style.webkitTransform = target.style.transform = `translate(${x}px, ${y}px)`
 
	// update the posiion attributes
	target.setAttribute(`data-x`, x)
	target.setAttribute(`data-y`, y)
}


// Screensaver
// const screenSaverDelay = 30 * 1000
// let interval
// let screensaverEl
// function startScreensaverWait(){
// 	clearInterval(interval)
// 	interval = setTimeout(showScreensaver, screenSaverDelay)
// }
// function stopScreensaver(){
// 	if(screensaverEl) screensaverEl.remove()
// 	startScreensaverWait()
// }
// function onMouseMove(){
// 	startScreensaverWait()
// }
// document.body.addEventListener(`mousemove`, onMouseMove)
// function showScreensaver(){
// 	screensaverEl = document.createElement(`div`)
// 	applyStyles(screensaverEl, {
// 		position: `fixed`,
// 		top: 0,
// 		right: 0,
// 		bottom: 0,
// 		left: 0,
// 		width: `100vw`,
// 		height: `100vh`,
// 		zIndex: 9998,
// 	})
// 	screensaverEl.innerHTML = `
// 		<iframe src='https://aaa-2021.netlify.app/screensaver' style='border:0;width:100vw;height:100vh;position:absolute'></iframe>
// 		<div style='position:absolute;width:100vw;height:100vh'></div>
// 	`
// 	const overlay = screensaverEl.querySelector(`div`)
// 	overlay.addEventListener(`mousemove`, stopScreensaver)
// 	overlay.addEventListener(`click`, stopScreensaver)
// 	document.body.appendChild(screensaverEl)
// }


// Clock
const clock = document.querySelector(`[data-clock]`)
let delimiter = false
const delimiterStyle = `width:5px;text-align:center;display:inline-block;margin-right:1px`
if(clock){
	setInterval(changeTime, 1000)
}
function changeTime(){
	const time = new Date()
	let text = formatAMPM(time)
	if(!delimiter){
		text = text.replace(`:`, `<span style='${delimiterStyle}'> </span>`)
	}
	else{
		text = text.replace(`:`, `<span style='${delimiterStyle}'>:</span>`)
	}
	delimiter = !delimiter
	clock.innerHTML = text
}
changeTime()
function formatAMPM(date) {
	var hours = date.getHours()
	var minutes = date.getMinutes()
	var ampm = hours >= 12 ? `PM` : `AM`
	hours = hours % 12
	hours = hours ? hours : 12 // the hour '0' should be '12'
	minutes = minutes < 10 ? `0`+minutes : minutes
	var strTime = hours + `:` + minutes + ` ` + ampm
	return strTime
}